import Analytics from 'components/Analytics'
import CategoryHero from 'components/CategoryHero'
import CategoryList from 'components/CategoryList'
import Pagination from 'components/Pagination'
import PostsList from 'components/PostsList'
import SEO from 'components/SEO'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const CategoryTemplate = ({
  data: {
    category,
    posts,
    categoryData: { categories },
  },
  pageContext: { basePath, pageCount, currentPageNumber, pageUrl },
}) => (
  <>
    <SEO
      defaultMeta={{ metaTitle: category.title }}
      pageMeta={{
        ...category.meta,
        noIndex: currentPageNumber !== 1,
      }}
      pagePath={pageUrl}
    />
    <Analytics {...category.analytics} />
    <CategoryList categories={categories} activeCategoryName={category.title} />
    <CategoryHero {...category} />

    <PostsList posts={posts.nodes} />

    <Pagination basePath={basePath} currentPageNumber={currentPageNumber} pageCount={pageCount} />
  </>
)

export default CategoryTemplate

CategoryTemplate.propTypes = {
  pageContext: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    pageCount: PropTypes.number.isRequired,
    currentPageNumber: PropTypes.number.isRequired,
    pageUrl: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    category: PropTypes.object.isRequired,
    posts: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    categoryData: PropTypes.shape({
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          slug: PropTypes.shape({ current: PropTypes.string.isRequired }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query CategoryQuery($id: String!, $limit: Int!, $skip: Int!) {
    category: sanityCategory(id: { eq: $id }) {
      meta {
        ...MetaCard
      }
      analytics {
        ...AnalyticsCard
      }
      title
      subtext
      slug {
        current
      }
      image {
        ...ImageWithPreview
      }
    }
    posts: allSanityPost(
      filter: { category: { elemMatch: { id: { eq: $id } } } }
      limit: $limit
      sort: { fields: [publishedAt], order: DESC }
      skip: $skip
    ) {
      nodes {
        id
        title
        category {
          title
          slug {
            current
          }
        }
        slug {
          current
        }
        excerpt
        image {
          ...ImageWithPreview
        }
      }
    }
    categoryData: allSanityCategory(sort: { fields: [title], order: ASC }) {
      categories: nodes {
        id
        title
        slug {
          current
        }
      }
    }
  }
`
